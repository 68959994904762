<template>
    <modal ref="modalAgregarServicio" tamano="modal-lg" titulo="Agregar servicio" adicional="Guardar" @adicional="agregarServicios">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <p class="input-label-top">Taller</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar taller" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Nombre del servicio</p>
                <el-input v-model="direccionTaller" placeholder="Nombre" class="w-100" size="small" />
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Tipo de servicio</p>
                <el-input v-model="direccionTaller" class="w-100" size="small" />
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Descripción del servicio</p>
                <el-input v-model="nombreResponsable" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" class="w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen de taller',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            nombreTaller: '',
            direccionTaller: '',
            nombreResponsable: '',
            numContacto: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAgregarServicio.toggle()
        },
        agregarServicios(){
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
